<template lang="">
    <template>
        <v-row>
          <v-col
            v-for="n in 9"
            :key="n"
            class="d-flex child-flex"
            cols="4"
          >
            <v-img
              :src="`https://picsum.photos/500/300?image=${n * 5 + 10}`"
              :lazy-src="`https://picsum.photos/10/6?image=${n * 5 + 10}`"
              aspect-ratio="1"
              class="grey lighten-2"
            >
            
            </v-img>
          </v-col>
        </v-row>
        <!-- <lightgallery :settings="{ speed: 500, plugins: plugins ,mode: 'lg-fade'}" :onInit="onInit" :onBeforeSlide="onBeforeSlide">
              <a data-lg-size="1406-1390" class="gallery-item"
                  data-src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                  data-sub-html="<p> Location - </p>">
                  <img class="img-responsive"
                      src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
              </a>
              <a data-src="merge.png">
                  <img alt="img2" src="../public/merge.png" />
              </a>
              <a data-lg-size="1406-1390" class="gallery-item"
                  data-src="merge.png"
                  data-sub-html="<p> Location - </p>">
                  <img class="img-responsive"
                      src="../public/merge.png" />
              </a>
              <a data-lg-size="1406-1390" class="gallery-item"
                  data-src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
                  data-sub-html="<p> Location - </p>">
                  <img class="img-responsive"
                      src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
              </a>
              <a data-src="merge.png">
                  <img alt="img2" src="../public/merge.png" />
              </a>
              <a data-lg-size="1406-1390" class="gallery-item"
                  data-src="merge.png"
                  data-sub-html="<p> Location - </p>">
                  <img class="img-responsive"
                      src="../public/merge.png" />
              </a>
              ...
          </lightgallery> -->
      </template>
</template>
<script>
// import Lightgallery from 'lightgallery/vue';
// import lgZoom from 'lightgallery/plugins/zoom';
// import lgVideo from 'lightgallery/plugins/video';

export default {
    // name: 'GalleryComponent',
    // components: {
    //     // Lightgallery,
    // },
    // data: () => ({
    //     plugins: [lgZoom, lgVideo],
    // }),
    // methods: {
    //     onInit: () => {
    //         console.log('lightGallery has been initialized');
    //     },
    //     onBeforeSlide: () => {
    //         console.log('calling before slide');
    //     },
    // },
    
}
</script>
<style  lang="css">
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css');
@import url('https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css');
</style>