<template>
    <transition name="slide-in">
        <div class="carousel-item" v-show="currentSlide === index">
            <img :src="slide"/>
        </div>
    </transition>
</template>

<script>
export default {
    props: ["slide","currentSlide", "index"]
}
</script>

<style scoped>
.carousel-item{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.slide-in-enter-active,
.slide-in-leave-active {
    transition: opacity 0.5s ease;
}
.slide-in-enter-from {
    opacity: 0;
}
.slide-in-leve-to {
    opacity: 0;
}

</style>