
<template lang="">
    <div id="block-top-a" class="bg-white">
        <div class="block-top-a wrapper max-width100">
          <section id="top-a" class="grid-block">
            <CarouselComponent :slides="slides"> </CarouselComponent>
            <div class="grid-box width100 grid-h">
              <div class="module mod1-box mod-box-default deepest">
                <div
                  id="gallery-6-63aac551659e3"
                  class="wk-slideshow wk-slideshow-default"
                  data-widgetkit="slideshow"
                  data-options='{"style":"default","width":"auto","height":"auto","autoplay":1,"order":"default","interval":5000,"duration":500,"index":0,"navigation":1,"buttons":1,"slices":20,"animated":"fade","caption_animation_duration":500,"lightbox":0}'
                >
                  <div>
                    
                     
                    <div class="next"></div>
                    <div class="prev"></div>
                    <!-- <div class="caption"></div> -->
                    <ul class="captions">
                      <li></li>
                      <li></li>
                      <li></li>
                    </ul>
                  </div>
                  <ul class="nav">
                    <li><span></span></li>
                    <li><span></span></li>
                    <li><span></span></li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
</template>
<script>
import CarouselComponent from './carousel/CarouselComponent.vue';  
export default {
    name: 'BlockTopAComponent',
    components: {CarouselComponent},
    data: () => ({
      slides: [ 
      "/cache/widgetkit/gallery/6/slider1a-116a60ee0e.jpg",
      "/cache/widgetkit/gallery/6/slider2a-b5e6730f26.jpg",
      "/cache/widgetkit/gallery/6/slider3a-5fc5bfb3fa.jpg",
      "/cache/widgetkit/gallery/6/slider1a-116a60ee0e.jpg",
      // "https://i.picsum.photos/id/1037/900/400.jpg?hmac=QcjNl48cKsn8x3bOuYEdPliJsSZo_8VxHxRrLWtM5Kg"
    ]

    })
  }
</script>
<style lang="">
    
</style>
