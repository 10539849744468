<template>
  <article class="item">
    <div class="content clearfix">
      <div class="latest-post">
        <h2>Peeling kawitacyjny</h2>
        <ul>
          <li>
            <p style="margin-bottom: 0cm">
              oczyszczenie sk&oacute;ry i zanieczyszczeń
            </p>
          </li>
          <li>
            <p style="margin-bottom: 0cm">
              lifting sk&oacute;ry &ndash; zabieg ten
              aktywuje mikrokrążenie krwi, metabolizm,
              dotlenia sk&oacute;rę, wzmaga produkcję
              kolagenu i elastyny
            </p>
          </li>
          <li>
            <p style="margin-bottom: 0cm">
              wtłaczanie (nawilżanie) &ndash; umożliwia
              wtłoczenie aktywnych kosmetyk&oacute;w w
              głąb sk&oacute;ry, przez co zwiększa ich
              działanie
            </p>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>
