<template>
  <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"> -->

  <article class="item">
    <div class="content clearfix">
      <p
        dir="ltr"
        id="docs-internal-guid-c655ca01-7fff-3ee6-d8a0-13c6d9f96811"
        style="
          line-height: 1.2;
          margin-top: 0pt;
          margin-bottom: 0pt;
          margin-left: 160px;
        "
      >
        <span
          style="
            font-size: 18pt;
            font-family: 'Comic Sans MS';
            color: #000000;
            background-color: transparent;
            font-weight: 700;
            font-style: normal;
            font-variant: normal;
            text-decoration: none;
            vertical-align: baseline;
            white-space: pre;
            white-space: pre-wrap;
            align: center;
          "
          >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;CENNIK</span
        >
      </p>

      <br />
    </div>
    <table style="width: 100%; border: solid white 1px">
      <thead>
        <tr style="border: solid white 1px"></tr>
      </thead>

      <tbody></tbody>
    </table>
    <DataTable :items="tickets" />
    <table style="width: 100%; border: solid white 1px">
      <thead>
        <tr style="border: solid white 1px"></tr>
      </thead>

      <tbody></tbody>
    </table>
    <!-- <tbody>
      <tr v-for="(table, index) in users" :key="table.date">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ table.date }}</td>
        <td>{{ table.name }}</td>
        <td>{{ table.address }}</td>
      </tr>
    </tbody> -->
  </article>

  <div>
    <!-- <tbody>
      <tr v-for="(user, index) in users" :key="user.date">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ table.date }}</td>
        <td>{{ table.name }}</td>
        <td>{{ table.address }}</td>
      </tr>
    </tbody> -->
    <!-- <tbody>
      <tr v-for="(user, key, index) in CennikJson.ofertaDane" :key="index">
       <td>{{user}} </td> <td>{{ key }}</td>  <td>{{ index }}</td>
      </tr>
      <tr v-for="(table, index) in CennikJson.ofertaDane" :key="table.date">
        <th scope="row">{{ index + 1 }}</th>
        <td>{{ table.date }}</td>
        <td>{{ table.name }}</td>
        <td>{{ table.address }}</td>
      </tr>
    </tbody> -->
  </div>
</template>
<script>
// import Cennik from './../../public/cennik.json';

// import CennikJson from "./../../public/cennik.json";

export default {

  data() {
    return {
        tickets: [],
    };
  },
  created() {
    this.fetchTickets();
  },

  methods: {
    fetchTickets() {
      fetch("/cennik.json")
        .then((res) => res.json()) //returning a promise To extract the JSON body content from the response
        .then((resJson) => {
          this.tickets = resJson;
          console.log(resJson);
        });
    },
  },
  
};
</script>
<script setup>
// import { ref } from "vue";
import DataTable from "./../components/DataTable.vue"; //'./components/DataTable.vue';
// import Cennik from './../../public/cennik.json'
// const tableData = ref([
//   {
//     date: '2016-05-03',
//     name: 'Tom',
//     address: 'No. 189, Grove St, Los Angeles',
//   },
//   {
//     date: '2016-05-02',
//     name: 'Tom',
//     address: 'No. 189, Grove St, Los Angeles',
//   },
//   {
//     date: '2016-05-04',
//     name: 'Tom',
//     address: 'No. 189, Grove St, Los Angeles',
//   },
//   {
//     date: '2016-05-01',
//     name: 'Tom',
//     address: 'No. 189, Grove St, Los Angeles',
//   },
// ])
// const ofertaDane = ref([
//   { Nazwa: "ZABIEGI PIELĘGNACYJNE", Cena: "0" }, ////////////////////////////////////////////////////////
//   { Nazwa: "Oczyszczanie twarzy", Cena: "170" },
//   { Nazwa: "Oczyszczanie pleców", Cena: "200" },
//   { Nazwa: "Masaż twarzy", Cena: "60" },
//   { Nazwa: "Maski ", Cena: "od 50" },
// ]);
//   { Nazwa: "Zabieg z kwasami (twarz)", Cena: "90" },
//   { Nazwa: "Zabieg z kwasami (twarz, szyja)", Cena: "130" },
//   { Nazwa: "Zabieg z kwasami (twarz, szyja,dekolt)", Cena: "180" },
//   { Nazwa: "Zabieg bankietowy b-like peel", Cena: "od 90" },
//   // { Nazwa: "Zabieg z kwasem glikolowym charm medi", Cena: "150" },
//   { Nazwa: "Kawitacja", Cena: "70" },
//   { Nazwa: "Zabieg odświeżający (kawitacja + sonofereza + maska)", Cena: "150" },
//   { Nazwa: "Zabieg kawitacja + ultradźwięki / fala radiowa ", Cena: "190" },
//   { Nazwa: "Zabieg pielęgnacyjny masaż, ampułka, maska ", Cena: "140" },
//   ////////////////////////
//   {
//     Nazwa: "Zabieg nawilżająco liftingujacy twarz, szyja dekolt  ",
//     Cena: "170",
//   },
//   // { Nazwa: "Wild berry ferment peel", Cena: "50" },
//   // { Nazwa: "Wild berry ferment peel + kawitacja", Cena: "90" },
//   { Nazwa: "Drenaż limfatyczny twarzy", Cena: "70" },
//   { Nazwa: "Drenaż limfatyczny – ‘ciężkie nogi’", Cena: "80" },
//   { Nazwa: "Biologiczny drenaż ciała", Cena: "120" },
//   // { Nazwa: "Mezoterapia ", Cena: "155" },
//   { Nazwa: "Mezoterapia frakcyjna (twarz)", Cena: "170" },
//   { Nazwa: "Mezoterapia (twarz, szyja)", Cena: "200" },
//   { Nazwa: "Mezoterapia (twarz, szyja, dekolt) ", Cena: "250" },
//   { Nazwa: "Mikrodermabrazja", Cena: "110" },
//   { Nazwa: "Znieczulenie do zabiegu", Cena: "30" },
//   { Nazwa: "Mikrodermabrazja + maska", Cena: "160" },
//   { Nazwa: "Oczyszczanie wodorowe (twarz)", Cena: "180" },
//   { Nazwa: "Oczyszczanie wodorowe (twarz, szyja)", Cena: "210" },
//   { Nazwa: "Oczyszczanie wodorowe (twarz, szyja, dekolt)", Cena: "250" },
//   { Nazwa: "Makijaż wieczorowy", Cena: "150" },
//   { Nazwa: "Makijaż ślubny", Cena: "170" },
//   { Nazwa: "Przedłużanie rzęs 1:1", Cena: "150" },
//   { Nazwa: "Przedłużanie rzęs 2:1", Cena: "180" },
//   { Nazwa: "Uzupełnienie rzęs", Cena: "120" },
//   { Nazwa: "Lifting rzęs + farbka", Cena: "90" },
//   { Nazwa: "Przekłucie uszu ", Cena: "90" },
//   { Nazwa: "STYLIZACJA PAZNOKCI", Cena: "0" },//////////////////////////////////////////////////////
//   {
//     Nazwa: "Przedłużanie paznokci ( żel, akryl, akryżel) ",
//     Cena: "od 160",
//   },
//   { Nazwa: "Uzupełnienie ( żel, akryl, akryżel)  ", Cena: "od 120" },
//   { Nazwa: "Dorobienie paznokcia", Cena: "od 15" },
//   { Nazwa: "Manicure z odżywka ", Cena: "60" },
//   { Nazwa: "Manicure z malowaniem", Cena: "80" },
//   { Nazwa: "Manicure japoński z odżywką", Cena: "70" },
//   { Nazwa: "Manicure IBX ", Cena: "70" },
//   //   {  Nazwa: "-manicure IBX", Cena: '60zł' },
//   { Nazwa: "Manicure Hybrydowy (kolor) ", Cena: "90" },
//   { Nazwa: "Zdobienia/ french/ ombre ", Cena: "od 10" },
//   { Nazwa: "Dodatkowa warstwa baza/żel", Cena: "10" },
//   { Nazwa: "Utwardzenie naturalnych paznokci akrylożelem z hybrydą", Cena: "120" },

//   { Nazwa: "Usunięcie hybrydy z odżywką", Cena: "35 " },
//   { Nazwa: "Usunięcie akrylu/ żelu ", Cena: "45" },
//   { Nazwa: "Pedicure podstawowy z odżywka", Cena: "95" },
//   { Nazwa: "Pedicure kolor/ zdobienia ", Cena: "od 110" },
//   {
//     Nazwa: "Pedicure leczniczy (modzele, nagniotki, odciski)",
//     Cena: "od 110",
//   },
//   { Nazwa: "Korekta paznokcia akrylem ze srebrem", Cena: "25" },
//   { Nazwa: "Pedicure hybrydowy (kolor)", Cena: "120" },
//   { Nazwa: "Pedicure hybrydowy zdobienia /french ", Cena: "od 135" },

//   { Nazwa: "HENNA", Cena: "0" },/////////////////////////////////////////////////////////////////////

//   { Nazwa: "Henna brwi z regulacją ", Cena: "40" },
//   { Nazwa: "Henna rzęs", Cena: "25" },
//   { Nazwa: "Henna komplet", Cena: "55" },
//   { Nazwa: "Henna pudrowa z regulacją", Cena: "75" },
//   { Nazwa: "Regulacja brwi", Cena: "25" },

//   { Nazwa: "DEPILACJA", Cena: "0" },/////////////////////////////////////////////////////////////////

//   { Nazwa: "Wąsik / broda", Cena: "20" },
//   { Nazwa: "Twarz", Cena: "50" },
//   { Nazwa: "Pachy", Cena: "45" },
//   { Nazwa: "Nogi", Cena: "110" },
//   { Nazwa: "Łydki", Cena: "60" },
//   { Nazwa: "Ręce", Cena: "60" },
// ];
</script>
