// import "bootstrap/dist/css/bootstrap.min.css"
// import "bootstrap"
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Vuelidate from 'vuelidate'
import emailjs from 'emailjs-com';
// import CarouselComponent from './components/carousel/CarouselComponent.vue';

createApp(App).use(router,Vuelidate,emailjs).mount('#app')
