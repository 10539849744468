<template>
    
  <!-- <section>
    <div>
      <v-layout>
        <v-flex
          v-for="(gorsel, gorselIndex) in gorseller"
          :key="gorselIndex"
          class="ma-2"
          style="margin-right: 10px"
          @click="index = gorselIndex"
        >
          <img width="180" height="180" :src="gorsel.url" class="resimKutusu" />
        </v-flex>
        <LightGallery
          :images="gorseller"
          :index="index"
          :disable-scroll="false"
          @close="index = null"
        />
      </v-layout>
    </div>
  </section> -->
  <!-- <lightgallery :settings="{ speed: 500, plugins: plugins ,mode: 'lg-fade'}" :onInit="onInit" :onBeforeSlide="onBeforeSlide"> -->
    <!-- <v-for = "photo in photos"></v-for> -->
    <!-- <a v-for="item in items">
        {{ item }}
    </a> -->
    <!-- <a   v-for="item in items" v-bind:data-src="item" :key="item">
        <img style="width: 400px" alt="img2"  v-bind:src="item" />
    </a> -->
    <!-- <a data-lg-size="1406-1390" class="gallery-item"
        data-src="merge.png"
        data-sub-html="<p> Location - </p>">
        <img class="img-responsive"
            src="../public/merge.png" />
    </a>
    <a data-lg-size="1406-1390" class="gallery-item"
        data-src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80"
        data-sub-html="<p> Location - </p>">
        <img class="img-responsive"
            src="https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80" />
    </a>
    <a data-src="merge.png">
        <img alt="img2" src="../public/merge.png" />
    </a>
    <a data-lg-size="1406-1390" class="gallery-item"
        data-src="merge.png"
        data-sub-html="<p> Location - </p>">
        <img class="img-responsive"
            src="../public/merge.png" />
    </a> -->
    ...
<!-- </lightgallery> -->
</template>

<script>

//export default {
//   data: () => ({
//     gorseller: [
//       { title: "img 1", url: "https://picsum.photos/500/300?image=1" },
//       { title: "img 2", url: "https://picsum.photos/500/300?image=2" },
//     ],
//     index: null,
//   }),
//   components: {},
// };
// import Lightgallery from 'lightgallery/vue';
import lgZoom from 'lightgallery/plugins/zoom';
import lgVideo from 'lightgallery/plugins/video';
     

export default {
    name: 'App',
    components: {
        // Lightgallery,
    },
    data: () => ({
        plugins: [lgZoom, lgVideo],
        items: ["/images/merge.png",
    'https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1406&q=80',
    'https://images.unsplash.com/photo-1581894158358-5ecd2c518883?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=240&q=80',
    ]
    }),
    methods: {
        onInit: () => {
            console.log('lightGallery has been initialized');
        },
        onBeforeSlide: () => {
            console.log('calling before slide');
        },
    },
};
</script>

<style scoped>
.resimKutusu {
  border: 1px solid #cdcdcd;
  padding: 5px;
  background: #eeeeee;
  border-radius: 5px;
  cursor: pointer;
}
</style>
