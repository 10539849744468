<template>
  <h1 class="gallerytitle">Galeria</h1>
  <div style="clear: both; width: 100%"></div>
  <div class="AG_album_wrap">
    <a @click="startMakijaz()" class="AG_album_thumb"
      ><span class="AG_album_thumb_img"
        ><img
          src="http://salonurodyilona.pl/plugins/content/admirorgallery/admirorgallery/thumbs/galeria/makijaz/Obraz 125 [800x600].jpg"
        /> </span
      ><span class="AG_album_thumb_label">makijaz</span></a
    >
    <a @click="start2Miejsce()" class="AG_album_thumb"
      ><span class="AG_album_thumb_img"
        ><img
          src="http://salonurodyilona.pl/plugins/content/admirorgallery/admirorgallery/thumbs/galeria/2miejsce/2.jpg"
        /> </span
      ><span class="AG_album_thumb_label">2miejsce</span></a
    >
    <a @click="startPaznokcie()" class="AG_album_thumb"
      ><span class="AG_album_thumb_img"
        ><img
          src="http://salonurodyilona.pl/plugins/content/admirorgallery/admirorgallery/thumbs/galeria/paznokcie/320 [800x600].JPG"
        /> </span
      ><span class="AG_album_thumb_label">paznokcie</span></a
    ><br style="clear: both" />
  </div>
</template>
<script>
var gallery = [];
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

// var makijazPhoto = [
//   "1 [800x600].jpg",
//   "DSC_7335 [800x600].JPG",
//   "IMG_9085 [800x600].jpg",
//   "Kopia P1010003 [800x600].JPG",
//   "Kopia P1010042 [800x600].JPG",
//   "Kopia P1010139 [800x600].JPG",
//   "Obraz 012 [800x600].jpg",
//   "Obraz 125 [800x600].jpg",
//   "Obraz 137 [800x600].jpg",
//   "Obraz 140 [800x600].jpg",
//   "Obraz 146 [800x600].jpg",
//   "Obraz 147 [800x600].jpg",
//   "Obraz 167 [800x600].jpg",
//   "Obraz 198 [800x600].jpg",
//   "Obraz 202 [800x600].jpg",
//   "_MG_8881 [800x600].jpg",
//   "_MG_8883 [800x600].jpg",
//   "a (4) [800x600].jpg",
//   "jaa 018 [800x600].jpg",
//   "jaa 028 [800x600].jpg",
//   "jaa 030 [800x600].jpg",
//   "jaa 033 [800x600].jpg",
//   "jaa 042 [800x600].jpg",
//   "jaa 054 [800x600].jpg",
//   "jaa 057 [800x600].jpg",
//   "jaa 087 [800x600].jpg",
//   "jj5 [800x600].JPG",
//   "obr 44 [800x600].jpg",
// ];

// var paznokciePhoto = [
//   "1 (1) [800x600].JPG",
//   "1 (13) [800x600].JPG",
//   "1 (17) [800x600].JPG",
//   "1 (26) [800x600].JPG",
//   "1 (27) [800x600].JPG",
//   "1 (29) [800x600].JPG",
//   "1 (3) [800x600].JPG",
//   "1 (34) [800x600].JPG",
//   "1 (7) [800x600].JPG",
//   "1 (9) [800x600].JPG",
//   "320 [800x600].JPG",
//   "Obraz 003 [800x600].jpg",
//   "Obraz 006 [800x600].jpg",
//   "Obraz 009 [800x600].jpg",
//   "Obraz 010 [800x600].jpg",
//   "Obraz 016 [800x600].jpg",
//   "Obraz 018 [800x600].jpg",
//   "Obraz 025 [800x600].jpg",
//   "Obraz 029 [800x600].jpg",
//   "Obraz 030 [800x600].jpg",
//   "Obraz 036 [800x600].jpg",
//   "Obraz 060 [800x600].jpg",
//   "lo [800x600].JPG",
//   "1 (26) [800x600].png",
//   "1 (34) [800x600].png",
// ];
var drugiemiejscePhoto = require.context(
  '@/../public/images/sampledata/galeria/2miejsce',
  true,
  /^.*\.jpg$/
)//= ["1.jpg", "2.jpg", "3.jpg"];

const makijazPhoto = require.context(
  '@/../public/images/sampledata/galeria/makijaz',
  true,
  /^.*\.jpg$/
)
const paznokciePhoto = require.context(
  '@/../public/images/sampledata/galeria/paznokcie',
  true,
  /^.*\.jpg$/
)
export default {
  name: "App",
  data: () => ({}),
  methods: {
    onInit: () => {
      console.log("lightGallery has been initialized");
    },
    onBeforeSlide: () => {
      console.log("calling before slide");
    },
    startMakijaz: () => {
      console.log(makijazPhoto.keys());
      makijazPhoto.keys().forEach((element) => {
        //gallery = [];
        gallery.push({
          src:
            "/images/sampledata/galeria/makijaz/" +
            element,
          thumb:
            "/images/sampledata/galeria/makijaz/" +
            element,
        });
      });
      Fancybox.show(gallery, {}); //starts fancybox with the gallery object
      gallery = [];
    },
    startPaznokcie: () => {
      paznokciePhoto.keys().forEach((element) => {
        //gallery = [];
        gallery.push({
          src:
            "/images/sampledata/galeria/paznokcie/" +
            element,
          thumb:
            "/images/sampledata/galeria/paznokcie/" +
            element,
        });
      });
      // console.log(">>>____________________" + JSON.stringify(gallery));
      //var gallery = this.imgs; //your object with images
      Fancybox.show(gallery, {}); //starts fancybox with the gallery object
      gallery = [];
    },
    start2Miejsce: () => {
      drugiemiejscePhoto.keys().forEach((element) => {
        gallery.push({
          src:
            "/images/sampledata/galeria/2miejsce/" +
            element,
          thumb:
            "/images/sampledata/galeria/2miejsce/" +
            element,
        });
      });
      console.log(">>>____________________" + JSON.stringify(gallery));
      //var gallery = this.imgs; //your object with images
      Fancybox.show(gallery, {}); //starts fancybox with the gallery object
      gallery = [];
    },
  },
};
</script>

<style lang="css">
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lightgallery.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-zoom.css");
@import url("https://cdn.jsdelivr.net/npm/lightgallery@2.0.0-beta.4/css/lg-video.css");

.carousel__button.is-prev {
  left: 10px;
  border: solid rgb(213, 47, 156);
  color: rgb(213, 47, 156);
}

.carousel__button.is-next {
  right: 40px;
  border: solid rgb(213, 47, 156);
  color: rgb(213, 47, 156);
}

#gridimage {
  width: 210px;
  height: 128px;
  border: solid rgb(247, 247, 247) 3px;
  float: left;
  background-position: bottom right;
  background-repeat: no-repeat;
  margin: 0 3px 3px;
  padding: 0 4px 4px 0;
}

.AG_classic .ag_imageThumb {
  border-color: #80808000;
}

.AG_classic .ag_imageThumb:hover {
  background-color: #fea804;
}

/* PAGINATION AND ALBUM STYLE DEFINITIONS */
#AG_01 a.AG_album_thumb,
#AG_01 div.AG_album_wrap,
#AG_01 a.AG_pagin_link,
#AG_01 a.AG_pagin_prev,
#AG_01 a.AG_pagin_next {
  border-color: #808080;
}

#AG_01 a.AG_album_thumb:hover,
#AG_01 a.AG_pagin_link:hover,
#AG_01 a.AG_pagin_prev:hover,
#AG_01 a.AG_pagin_next:hover {
  background-color: #fea804;
}

#AG_01 div.AG_album_wrap h1,
#AG_01 a.AG_pagin_link,
#AG_01 a.AG_pagin_prev,
#AG_01 a.AG_pagin_next {
  color: #808080;
}
</style>
