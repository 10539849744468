<template>
    <article class="item">
        <div class="content clearfix">
          <div class="latest-post">
            <h2>Mikrodermabrazja</h2>
            <p style="margin-bottom: 0cm">
              Dermabrazja diamentowa to bezinwazyjny zabieg
              wygładzenia sk&oacute;ry przy użyciu styrylnych
              ostrzy diamentowych kt&oacute;re ścierają
              wierzchnią warstwę sk&oacute;ry. Starte resztki
              brud i martwy nask&oacute;rek są automatycznie
              odsysane. Zabieg pozwala usunąć złuszczenia,
              nier&oacute;wności, skazy i zmarszczki oraz
              niepożądane przebarwienia sk&oacute;ry.
            </p>
          </div>
        </div>
      </article>
</template>