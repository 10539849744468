import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import OfertaView from '../views/OfertaView.vue'
import GaleriaView from '../views/GaleriaView.vue'
import KontaktView from '../views/KontaktView.vue'
import DrenazLimfatycznyView from '../views/DrenazLimfatyczny.vue'
import PeelingKawitacyjny from '../views/PeelengKawitacynyView.vue'
import MikrodermabrazjaView from '../views/MikrodermabrazjaView.vue'
import ZabiegiAntycelilitowe from '../views/ZabiegiAntycelulitoweView.vue'
import PaznokcieGallery from '../views/PaznokcieGallery.vue'
// import CarouselComponent from '../components/CarouselComponent.vue'; 

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/oferta',
    name: 'oferta',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: OfertaView//() => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/galeria',
    name: 'galeria',
    component: GaleriaView
  },
  {
    path: '/kontakt',
    name: 'kontakt',
    component: KontaktView
  },
  {
    path: '/drenaz-limfatyczny',
    name: 'drenaz-limfatyczny',
    component: DrenazLimfatycznyView
  },
  {
    path: '/peeling-kawitacyjny',
    name: 'peeling-kawitacyjny',
    component: PeelingKawitacyjny
  },
  {
    path: '/mikrodermabrazja',
    name: 'mikrodermabrazja',
    component: MikrodermabrazjaView
  },
  {
    path: '/zabiegi-antycelulitowe',
    name: 'zabiegi-antycelulitowe',
    component: ZabiegiAntycelilitowe
  },
  {
    path: '/paznokciegallery',
    name: 'paznokcie-gallery',
    component: PaznokcieGallery
  },
  // {
  //   path: 'paznokcie',
  //   name: 'Paznokcie',
  //   component: 
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
