<template>
    <article class="item">
        <div class="content clearfix">
          <div class="latest-post">
            <h2>Drenaż limfatyczny</h2>
            <p style="margin-bottom: 0cm">
              Drenaż limfatyczny pozwala na osiągnięcie
              głębszej penetracji i lepszego skutku zabiegu.
              Może być użyty do wielu zabieg&oacute;w w
              zakresie poprawy estetyki sk&oacute;ry takich
              jak:
            </p>
            <ul>
              <li>
                <p style="margin-bottom: 0cm">
                  trądzik i skazy sk&oacute;rne
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  odbarwienie sk&oacute;ry
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  obrzęki twarzy
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  podgrążone i opuchnięte miejsca podoczne
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  &bdquo;ciężkie nogi&rdquo; (opuchlizna
                  wodna, słabe krążenie)
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  panniculosis i złogi tłuszczowe
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  odstresowanie
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  zrelaksowanie
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  zniwelowanie napięcia i b&oacute;lu mięśni
                </p>
                <p style="margin-bottom: 0cm">
                  Drenaż limfatyczny umożliwia biologiczny
                  masaż osuszający, wspomagający ruch limfy,
                  przetaczając nadmiar płyn&oacute;w z
                  przestrzeni wok&oacute;ł i między
                  kom&oacute;rkami. Drenaż limfatyczny może
                  wspom&oacute;c usuwanie problem&oacute;w
                  zdrowotnych i zbędnych produkt&oacute;w
                  metabolizmu. Rewelacyjne efekty osiągane są
                  podczas redukcji cellulitu.
                </p>
                <p style="margin-bottom: 0cm">
                  Proponowane zabiegi:
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  drenaż twarzy i szyi
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  zabieg drenażu (&bdquo;ciężkich&rdquo;
                  n&oacute;g i cellulitis)
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  biologiczny drenaż ciała
                </p>
              </li>
            </ul>
          </div>
        </div>
      </article>
</template>