<template>
    <article class="item">
        <div class="content clearfix">
          <div class="latest-post">
            <h2>Zabiegi antycellulitowe</h2>
            <ul>
              <li>
                <p style="margin-bottom: 0cm">
                  kokosowa wyspa (fotka kokosowa wyspa)
                </p>
                <p style="margin-bottom: 0cm">
                  a) wskazania:
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  nadmierna tkanka tłuszczowa
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  celullit i utrata jędrności sk&oacute;ry
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  kuracja wyszczuplająca
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  sk&oacute;ra przesuszona, łuszcząca się
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  złe samopoczucie związane ze stresem
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  profilaktycznie dla utrzymania atrakcyjnego
                  wyglądu
                </p>
                <p style="margin-bottom: 0cm">
                  b) efekty zabiegu:
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  redukcja brzusznej tkanki tłuszczowej
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  wyszczuplenie pośladk&oacute;w i ud
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  redukcja cellulitu
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  efekt remodelujący sylwetkę
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  poprawa napięcia sk&oacute;ry
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  doskonałe wygładzenie i regeneracja
                  sk&oacute;ry
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  poprawa nawilżenia już po jednym zabiegu
                </p>
              </li>
              <li>
                <p style="margin-bottom: 0cm">
                  głęboki relaks i odprężenie
                </p>
              </li>
            </ul>
          </div>
        </div>
      </article>
</template>
