<template>
  <!-- <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm" crossorigin="anonymous"> -->

  <!-- <nav>
    <router-link to="/">Home</router-link> |
    <router-link to="/about">About</router-link>
  </nav> -->

  <div id="page2">
    <!-- <DataTable :items="idtems"/> -->
    <AppHeaderVue/>
    <BlockTopAComponent/>
    <BlockMain/>
    <FooterComponent/>
    <Popup v-if="popupTrigers.buttonTrigger"
    :TogglePopup="() => TogglePopup('buttonTrigger')">
      <h2>Informacja o cookies.</h2>
      <p>
        W związku z Rozporządzeniem Parlamentu Europejskiego i Rady (UE) 2016/679 (znanym też jako "RODO") pragniemy poinformować Cię, w jaki sposób przetwarzane są dane osobowe pozostawiane przez Ciebie podczas korzystania z serwisu.
W związku z przekazanymi danymi osobowymi podczas rejestracji na Salonurodyilona.pl informujemy:
1. Administratorem Pani/Pana danych osobowych (Imię Nazwisko i adres e-mail) jest Ilona Paluszek: "ADMINISTRATOR"), Adres do korespondencji za pomocą poczty tradycyjnej na adres: ul. Pogodna 50A lok 2 20 - 354 Lublin, telefonicznie: 667-266-851 lub drogą mailową: ilonakowalska1@op.pl
2. Pani/Pana dane osobowe są przetwarzane na podstawie art. 6 ust. 1 lit. a i b dyrektywy Parlamentu Europejskiego i Rady (UE) 2016/680 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych przez właściwe organy do celów zapobiegania przestępczości, prowadzenia postępowań przygotowawczych, wykrywania i ścigania czynów zabronionych i wykonywania kar, w sprawie swobodnego przepływu takich danych oraz uchylająca decyzję ramową Rady 2008/977/WSiSW, tj. w oparciu o zgodę osoby, której dane dotyczą, lecz nie na podstawie szczegółowych przepisów prawa, a na podstawie przekazanych danych osobowych, za zgodą osoby, której dane dotyczą.
4. Przetwarzanie odbywa się w celu identyfikacji użytkownika.
5. Dane osobowe nie pochodzą od stron trzecich.
6. Administrator nie zamierza przekazywać danych do państwa trzeciego lub organizacji międzynarodowej.
7. Administrator będzie przekazywał dane osobowe innym podmiotom, tylko na podstawie przepisów prawa oraz innym podmiotom, o ile zaistnieje taka konieczność, na podstawie umów powierzenia.
8. Dane osobowe będą przechowywane do czasu żądania zaprzestania ich przetwarzania.
9. Osoba, której dane dotyczą ma prawo do żądania od administratora dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania oraz o prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych.
10. Skargę nas działania Administratora można wnieść do Prezesa Urzędu Ochrony Danych Osobowych.
11. Podanie danych osobowych jest wymogiem do wykonania obowiązków Administratora. Ich nie podanie spowoduje brak możliwości korzystania z Salonurodyilona, czy nawiązania korespondencji.
12. Administrator nie przewiduje zautomatyzowanego podejmowania decyzji.
UWAGA: Te ustawienia mają zastosowanie jedynie w przeglądarce i na urządzeniu, którego teraz używasz.
      </p>
    </Popup>
  </div>
</template>

<script>
import { ref } from 'vue'
import Popup from './components/Popup.vue';
import AppHeaderVue from './components/AppHeader.vue';
import BlockTopAComponent from './components/BlockTopAComponent.vue'
import BlockMain from './components/BlockMain.vue'
import FooterComponent from './components/FooterComponent.vue';
// import DataTable from './components/DataTable.vue';
// import CarouselComponent from './components/carousel/CarouselComponent.vue';
// const items = ref([]);
export default {
  name: 'App',
  data(){
    // const items = [
    //   { Nazwa: "Wąsik / broda", Cena: "20" },
    //   { Nazwa: "Twarz", Cena: "50" },
    //   { Nazwa: "Pachy", Cena: "40" },
    //   { Nazwa: "Nogi", Cena: "110" },
    //   { Nazwa: "Łydki", Cena: "55" },
    //   { Nazwa: "Ręce", Cena: "60" },
    // ];
  },
  setup (){
    const popupTrigers = ref({
      buttonTrigger: false,
      timedTrigger: false
    });
    const TogglePopup = (trigger) => {

      popupTrigers.value[trigger] = !popupTrigers.value[trigger];
    }
    return {
      Popup,
      popupTrigers,
      TogglePopup
    }
  },
  created() {
    if(!(document.cookie.match(/^(?:.*;)?\s*cookieaccept\s*=\s*([^;]+)(?:.*)?$/)||[null])[1]){
      this.TogglePopup('buttonTrigger')
    }
  },
  components: {
    AppHeaderVue,
    BlockTopAComponent,
    BlockMain,
    FooterComponent,
    Popup,
    // DataTable

    // CarouselComponent
    // HelloWorld
  }
}
</script>

<style>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-inner {
  background: #ffff;
  padding: 32px;
}
      tr.tb:hover {
        background-color: white;
      }
      td {
        padding: 10px;
        text-align: center;
      }
      ._4s7c {
        border: 1px solid red !important;
      }
      #kontakt {
        padding: 0px 10px;
      }
      #kontakt h3,
      #kontakt h4,
      #kontakt p {
        color: #d02e99;
      }
      #kontakt p {
        color: #9b126c;
        font-family: arial;
        font-size: 16px;
      }
      #kontakt h4 {
        margin-top: 100px;
      }
      h3,
      div,
      h4 {
        color: #262626;
      }

      .bg-white,
      .bg-dark {
        background: none;
      }

      BODY#page {
        background: #2b2a2a url("../public/images/tla/page-gradient-bg.png") 50% 0
          repeat-y;
      }
      #page2 {
        background: url("../public/images/tla/page-bg.png") 50% 500px no-repeat;
      }
      .menu-dropdown li.active .level1:before,
      #menu .menu-dropdown li.level1:hover .level1,
      #menu .menu-dropdown li.remain .level1,
      .menu-dropdown .dropdown-bg > div {
        background: none;
        color: #d52f9c;
      }
      #top-a .mod-box,
      #top-a .module {
        padding: 0px;
        border: none;
        margin: 0px;
      }
      .bg-white + .bg-white {
        margin-top: 0px;
      }
      DIV#block-main.bg-white,
      #maininner {
        margin-top: -170px;
        z-index: 100;
      }
      ASIDE#sidebar-a,
      #maininner {
        margin-top: -130px;
        z-index: 100;
      }

      #block-top-a .bg-white {
        padding: 0px 0px;
      }
      #block-header + * {
        padding-top: 0px;
      }
      .menu-dropdown a.level1 > span,
      .menu-dropdown span.level1 > span {
        height: 72px;
        line-height: 72px;
      }
      #header {
        height: 32px;
        position: relative;
      }
      .searchbox {
        height: 72px;
        padding: 0px 42px;
        border: none;
      }
      .searchbox input:-moz-placeholder {
        color: #ff00aa;
      }
      .searchbox input {
        height: 72px;
      }
      #block-top-a.bg-white,
      .bg-colored {
        padding: 0px 0px;
      }
      #block-top-a.bg-white {
        -webkit-box-shadow: 0px 0px 10px 1px black;
        box-shadow: 0px 0px 10px 1px black;
        border-top: 1px solid rgba(0, 0, 0, 0.15);
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      }
      #logo {
        position: absolute;
        top: -150px;
        left: 0px;
        z-index: 99999999;
      }
      #block-top-a ul.nav {
        position: absolute;
        bottom: 140px;
        left: 50%;
        margin-left: -220px;
        z-index: 101;
      }
      DIV.block-main.wrapper {
        position: relative;
        /*height:400px;*/
      }

      div#main {
        /*position:absolute;*/
        top: -160px;
        left: 0px;
        z-index: 99999999;
        background-color: #ff00aa;
        width: 980px;
        -webkit-box-shadow: 0px 0px 10px 1px black;
        box-shadow: 0px 0px 10px 1px black;
      }

      #block-header {
        border-bottom: none;
        background: #8c1c66 url("../public/images/tla/main-bg.png") 50% top no-repeat;
      }
      .menu-dropdown a.level1,
      .menu-dropdown span.level1 {
        padding-bottom: 0px;
        border: none;
      }
      .menu-dropdown a.level1,
      .menu-dropdown span.level1 {
        color: #d52f9c;
      }
      .menu-dropdown li.active .level1 {
        background: url("../public/images/tla/pypek.png") 50% bottom no-repeat;
        color: #d52f9c;
      }

      DIV#block-top-a {
        padding-top: 10px !important;
        padding-bottom: 10px !important;
        background: #cc0288 url("../public/images/tla/gradient.png") 50% top repeat-y;
      }
      .wk-slideshow-default .nav span {
        width: 150px;
        height: 5px;
        background: none;
        background: url("../public/images/tla/belka.png") 0 0 repeat;
      }
      .wk-slideshow-default .nav .active span {
        background: url("../public/images/tla/belka2.png") 0 0 repeat;
      }

      #ramka1 {
        background: url("../public/images/tla/main-gradient-bg.png") 50% 0 repeat-y;
        height: auto;
      }
      #ramka2 {
        background: url("../public/images/tla/main-bg.png") 50% top no-repeat;
      }
      #block-bottom-a {
        background: url("../public/images/tla/bottom-a-bg.png") 50% top no-repeat;
      }
      #block-bottom-a input,
      #block-bottom-a textarea {
        background: url("../public/images/tla/belka.png") 0 0 repeat;
        border: none;
      }
      ASIDE#sidebar-a .module {
        background: #ab247d url("../public/images/tla/aside-bg2.html") 50% top no-repeat;
        -webkit-box-shadow: 0px 0px 10px 1px black;
        box-shadow: 0px 0px 10px 1px black;
      }
      .menu-sidebar li.level1 {
        padding-left: 10px;
        border-top: none;
        background: url("../public/images/tla/li-side-bg.png") 50% bottom no-repeat;
      }
      .menu-sidebar li.level1 span {
        color: black;
      }
      #page .mod-box-default .menu-sidebar :hover,
      #page .mod-box-grey .menu-sidebar :hover {
        color: #363636;
      }
      div#menubar {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 99999999999;
        background: #3b3b3a url("../public/images/tla/menu-bg.png") right 0 no-repeat;
        -webkit-box-shadow: 0px 0px 10px 1px black;
        box-shadow: 0px 0px 10px 1px black;
      }
      .menu-sidebar li.parent.active .level1 > span {
        background-position: 190px -140px;
      }
      .menu-sidebar li.parent .level1 > span {
        background-position: 190px 0px;
      }
      .menu-sidebar li.parent .level1 > span:hover {
        background-position: 190px -70px;
      }
      .menu-sidebar li.parent.active .level1 > span:hover {
        background-position: 190px -210px;
      }
      LI.level1.item142 {
        background: none;
      }
      ul.slides li {
        z-index: 0 !important;
      }
      .ostatni {
        padding-bottom: -10px;
        height: 304px;
      }
      .promocje {
        background: none !important;
        box-shadow: none !important;
      }
      table.oferta td {
        font-size: 12px;
        font-family: arial;
        padding: 3px;
      }
      table.oferta h3 {
        font-size: 16px;
        font-family: arial;
      }

      .ofer td {
        vertical-align: top;
      }
      p,
      td,
      div {
        font-family: arial;
      }
      .AG_classic .ag_imageThumb,
      A.AG_album_thumb {
        background-color: #3b3b3b;
      }
      .AG_classic .ag_imageThumb:hover,
      A.AG_album_thumb {
        background-color: #aa0093 !important;
        border-color: black;
      }
      DIV.AG_pagin_wrap {
        border-top: 1px solid black;
      }
      #AG_01 a.AG_album_thumb,
      #AG_01 div.AG_album_wrap,
      #AG_01 a.AG_pagin_link,
      #AG_01 a.AG_pagin_prev,
      #AG_01 a.AG_pagin_next,
      span.AG_pagin_current {
        border: none;
        margin-top: 0px;
        padding-top: 10px;
      }
      span.AG_pagin_current {
        background: url("../public/images/tla/span-galery-bg.png") 0 top no-repeat;
      }
      #AG_01 a.AG_pagin_link:hover,
      #AG_01 a.AG_pagin_prev:hover,
      #AG_01 a.AG_pagin_next:hover {
        background: none !important;
        color: black;
        text-decoration: underline;
      }
      #AG_01 div.AG_album_wrap h1,
      #AG_01 a.AG_pagin_link,
      #AG_01 a.AG_pagin_prev,
      #AG_01 a.AG_pagin_next {
        color: black !important;
      }
      h1.gallerytitle {
        font-weight: normal !important;
        color: black;
      }
      SPAN.AG_album_thumb_label {
        color: black !important;
        text-transform: uppercase;
      }
      a.AG_album_thumb:hover {
        background-color: #d300b4 !important;
      }
      DIV.AG_album_wrap {
        padding-left: 100px;
      }
</style>
