<template lang="">
    <div class="popup">
        <div class="popup-inner">
            <slot />
            <button class="popup-clodse" @click="TogglePopupMethod()">
                Zamknij
            </button>
        </div>
    </div>
</template>
<script>
export default {
    props: ['TogglePopup'],
    methods: {
        TogglePopupMethod() {
            var today = new Date();
            var dd = String(today.getDate()).padStart(2, '0');
            var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
            var yyyy = today.getFullYear() + 1;

            today = mm + '/' + dd + '/' + yyyy;
            this.TogglePopup('buttonTrigger');
            document.cookie = "cookieaccept=yes; expires=" + today;
        }
    }
}
</script>
<style lang="scss" scoped>
.popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: justify;
    justify-content: center;
    //background-color: rgba(240, 169, 169, 0.2);

}

.popup-inner {
    background: #ffff;
    padding: 32px;
    text-align:center ;
    //text-align: justify;
    //color: red;
    //opacity: 50%;
}

</style>