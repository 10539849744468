<script setup>
import SearchBar from "./SearchBar.vue";

import { computed, defineProps, ref } from "vue";
const searchFilter = ref("");
const props = defineProps({
  items: {
    // type: Array,
    required: true,
  },
});
const filteredItems = computed(() => {
  if (searchFilter.value != "") {
    return props.items.filter(
      (item) =>
        item.Nazwa.toLowerCase().includes(searchFilter.value) ||
        item.Nazwa.includes(searchFilter.value) ||
        item.Cena.includes(searchFilter.value) ||
        item.Cena == 0
    );
  }
  return props.items;
});
const handleSearch = (search) => {
  searchFilter.value = search;
  // console.log(searchFilter.value);
  console.log(search);
};
</script>
<SearchBar @search="handleSearch" />
<template>
  <table>
    <SearchBar @search="handleSearch" />
  </table>
  <p
    dir="ltr"
    style="
      line-height: 1.2;
      margin-top: 0pt;
      margin-bottom: 0pt;
      text-align: center;
    "
  >
    <span
      style="
        font-size: 14pt;
        font-family: 'Comic Sans MS';
        color: rgb(0, 0, 0);
        background-color: transparent;
        font-weight: 700;
        font-style: normal;
        font-variant: normal;
        text-decoration: none;
        vertical-align: baseline;
        white-space: pre-wrap;
      "
    ></span>
  </p>
  <table style="width: 100% ; " border="0">
    <tr
      
      
      v-for="item in filteredItems"
      :key="item.id"
    >
      <td
        style="size: 200px; border: solid white 1px !important"
        v-if="item.Cena != 0"
      >
        {{ item.Nazwa }}
        <div style="float: right">{{ item.Cena }} zł</div>
      </td>
      <td v-if="item.Cena == 0"  style="
          font-family: 'Comic Sans MS';
          background-color: transparent !important;
          font-weight: 700;
          font-style: normal;
          font-variant: normal;
          text-decoration: none;
          vertical-align: baseline;
          white-space: pre-wrap;
          border: solid 0px !important;
        "
        >
        {{ item.Nazwa }}
      </td>
      <br /><br />
    </tr>
  </table>
</template>

<style>
td:hover {background-color: rgb(255, 255, 255);};

</style>
