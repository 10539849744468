<template lang="">
  <div id="ff_formdiv1" class="content_outline">
    <div class="bfPage-tl">
      <div class="bfPage-tr">
        <div class="bfPage-t"></div>
      </div>
    </div>
    <div class="bfPage-l">
      <div class="bfPage-r">
        <div class="bfPage-m bfClearfix">
          <form ref="form" @submit.prevent="submitForm">
            <p>
              <input
                style="width: 100%"
                v-model="state.name"
                name="user_name"
                type="text"
                placeholder="Imię i nazwisko"
              />
              <span v-if="v$.name.$error">
                {{ /*v$.email.$errors[0].$message*/ "Pole nie może być puste" }}
              </span>
            </p>
            <p>
              <input
                style="width: 100%"
                v-model="state.email"
                name="user_email"
                type="text"
                placeholder="Email"
              />
              <span v-if="v$.email.$error">
                {{
                  /*v$.email.$errors[0].$message*/ "Nie prawidłowy adres email!"
                }}
              </span>
            </p>
            <p>
              <textarea
                style="width: 100%; height: 37px"
                v-model="state.message"
                name="message"
                type="text"
                placeholder="Wiadomość..."
              />
              <span v-if="v$.message.$error">
                {{ /*v$.email.$errors[0].$message*/ "Pole nie może być puste" }}
              </span>
            </p>
            <input type="submit" value="Wyślij wiadomość..." />
          </form>
        </div>
      </div>
    </div>
    <div class="bfPage-bl">
      <div class="bfPage-br">
        <div class="bfPage-b"></div>
      </div>
    </div>
  </div>
</template>
<script>
import useValidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import { reactive, computed } from "vue";
import axios from "axios";
// import emailjs from "emailjs-com";
// import axios from "axios";
export default {
  setup() {
    const state = reactive({
      name: "",
      email: "",
      // password: {
      message:
        "",
      // confirm: "",
      // },
    });
    const rules = computed(() => {
      return {
        name: { required },
        email: { required, email },
        // password: {
        message: { required },
        // confirm: { required, sameAs: sameAs(state.password.password) },
        // },
      };
    });

    const v$ = useValidate(rules, state);

    return { state, v$ };
  },
  methods: {
    submitForm() {
      this.v$.$validate(); // checks all inputs
      if (!this.v$.$error) {
        this.sendEmail();
        alert(`Wiadomość , wysłana! `);
        // window.location.reload();
      } else {
        alert("Nieprawidłowe dane!!!");
      }
    },
    sendEmail() {
      this.v$.$validate(); // checks all inputs
      // console.log("aaa" + this.state.message);
      if (!this.v$.$error) {
        {
          axios
            .post("https://salonurodyilona.pl/sendemail/index.php", {
              name: this.state.name,
              email: this.state.email,
              message: this.state.message,
            })
            .then((response) => {
              console.log("successSU", response);
            })
            .catch((error) => {
              console.log(error.response);
            });
        }
        
      }
      // Reset form field
      this.name = "";
      this.email = "";
      this.message = "";
    },
  },
  data() {
    return {
      //   v$: useValidate(),
      email: "",
      message: "",
    };
  },

  validations() {
    return {
      email: { required },
      message: { required },
    };
  },
};
</script>
<style lang=""></style>
