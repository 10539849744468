<script setup>
import {defineEmits} from 'vue'
    const emit = defineEmits(['search'])
    const search = (e) => {
        // console.log(e.target.value)
        emit('search',  e.target.value);
    }
</script>


<template>
  <div>
    <!-- <form class="py-3 px-4 flex items-center" id="searchbox-99">
        <div> -->
            <!-- <input type="text" @input="search" placeholder="Szukaj"><label for=""></label>  -->
            <form id="searchbox-99" class="searchbox"  >
              <input type="text" style="font-color:white; " @input="search" name="searchword" placeholder="Szukaj...">
              <!-- <input type="hidden" name="task" value="search"><input type="hidden" name="option" value="com_search"><input type="hidden" name="Itemid" value="103"> -->
            <!-- </form>
        </div> -->
    </form>
  </div>
</template>



<style>

</style>