<template>
  <article class="item">
    <div class="content clearfix">
      <h3>Adres naszego salonu:</h3>
      <table border="0" cellpadding="1" cellspacing="1" style="width: 100%">
        <tbody class="noHover">
          <tr>
            <td style="width: 50%">
              <table
                border="0"
                cellpadding="1"
                cellspacing="1"
                style="width: 98%"
              >
                <tbody>
                  <tr>
                    <td style="border-bottom: 1px dotted black">
                      <div>
                        <img
                          alt=""
                          src="../../public/images/ikony/home.png"
                          style="padding: 5px"
                        />
                      </div>
                    </td>
                    <td style="border-bottom: 1px dotted black">
                      <div>
                        ul. Pogodna 50A lok 2<br />
                        20 - 354 Lublin
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td style="border-bottom: 1px dotted black">
                      <div>
                        <img
                          alt=""
                          src="../../public/images/ikony/phone.png"
                          style="padding: 5px"
                        />
                      </div>
                    </td>
                    <td style="border-bottom: 1px dotted black">
                      <div>tel. 667-266-851</div>
                    </td>
                  </tr>
                  <tr>
                    <td style="border-bottom: 1px dotted black">
                      <div>
                        <img
                          alt=""
                          src="../../public/images/ikony/clock.png"
                          style="padding: 5px"
                        />
                      </div>
                    </td>
                    <td style="border-bottom: 1px dotted black">
                      <div>
                        <span>poniedziałki i piątki od 11.00 do 18.00</span>
                      </div>
                      <div>
                        <span
                          >wtorki, środy oraz czwartki od 9.00 do 16.00</span
                        >
                      </div>
                      <div>
                        <span>soboty od 9.00 do 14.00 (lub na zapisy)</span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td>
              <div style="border: 2px solid black">
                <div class="moduletable">
                  <div id="map-7-639713af94222" class="wk-map wk-map-default" data-widgetkit="googlemaps" data-options="{&quot;title&quot;:&quot;&quot;,&quot;lat&quot;:&quot;51.2306509&quot;,&quot;lng&quot;:&quot;22.583647&quot;,&quot;icon&quot;:&quot;red-dot&quot;,&quot;popup&quot;:1,&quot;text&quot;:&quot;&quot;,&quot;mainIcon&quot;:&quot;red-dot&quot;,&quot;style&quot;:&quot;default&quot;,&quot;width&quot;:&quot;auto&quot;,&quot;height&quot;:200,&quot;mapTypeId&quot;:&quot;roadmap&quot;,&quot;zoom&quot;:13,&quot;mapCtrl&quot;:1,&quot;typeCtrl&quot;:0,&quot;directions&quot;:0,&quot;unitSystem&quot;:0,&quot;clusterMarker&quot;:0,&quot;styler_invert_lightness&quot;:0,&quot;styler_hue&quot;:&quot;&quot;,&quot;styler_saturation&quot;:0,&quot;styler_lightness&quot;:0,&quot;styler_gamma&quot;:0,&quot;adresses&quot;:[]}" style="height: 200px; width: 100%;"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1249.1508608665888!2d22.599962676317517!3d51.23193790496602!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x472256e33944b5a7%3A0x5ec8fb399b5c835c!2sPogodna%2050A%2C%2020-330%20Lublin!5e0!3m2!1spl!2spl!4v1672322014039!5m2!1spl!2spl" width="350" height="200" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" style="border: 0px;"></iframe></div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <p>&nbsp;</p>
    </div>
  </article>
</template>
<style>
.noHover {
  pointer-events: none;
}
</style>
