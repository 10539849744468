<template lang="">
    <div id="block-main" class="bg-white">
        <div class="block-main wrapper">
          <div id="main" class="grid-block">
            <div id="ramka1" class="grid-block">
              <div id="ramka2" class="grid-block">
                <div id="ramka3" class="grid-block">
                    
                  <div id="maininner" class="grid-box">
                    
                    <section id="content" class="grid-block">
                      <div id="system-message-container"></div>

                      <div id="system">
                        <router-view/>
                        
                      </div>
                    </section>
                  </div>
                  <!-- maininner end -->

                  <aside id="sidebar-a" class="grid-box">
                    <div id="ramka11">
                      <div id="ramka12">
                        <div id="ramka13">
                          <div class="grid-box width100 grid-v">
                            <div
                              class="module mod1-box mod-box-default deepest"
                            >
                              <ul class="menu menu-sidebar">
                                <li class="level1 item107 parent">
                                  <span class="separator level1 parent"
                                    ><span>Zabiegi pielęgnacyjne</span></span
                                  >
                                  <ul class="level2">
                                    <li class="level2 item128">
                                      <a
                                        href="index.php/zabiegi-pielegnacyjne/drenaz-limatyczny.html"
                                        class="level2"
                                        ><span><router-link to="/drenaz-limfatyczny" >Drenaż limfatyczny</router-link></span></a
                                      >
                                    </li>
                                    <li class="level2 item125">
                                      <a
                                        href="index.php/zabiegi-pielegnacyjne/peeling-kawitacyjny.html"
                                        class="level2"
                                        ><span><router-link to="./peeling-kawitacyjny" >Peeling kawitacyjny</router-link> </span></a
                                      >
                                    </li>
                                    <li class="level2 item129">
                                      <a
                                        class="level2"
                                        ><span>Oczyszczanie twarzy</span></a
                                      >
                                    </li>
                                    <li class="level2 item130">
                                      <a
                                        class="level2"
                                        ><span>Masaż</span></a
                                      >
                                    </li>
                                    <li class="level2 item131">
                                      <a
                                        class="level2"
                                        ><span>Eksfoliacja kwasami</span></a
                                      >
                                    </li>
                                    <li class="level2 item132">
                                      <a
                                        class="level2"
                                        ><span>Zabiegi liftingujące</span></a
                                      >
                                    </li>
                                    <li class="level2 item133">
                                      <a
                                        class="level2"
                                        ><span>Przedłużanie rzęs</span></a
                                      >
                                    </li>
                                    <li class="level2 item134">
                                      <a
                                        class="level2"
                                        ><span>Makijaż</span></a
                                      >
                                    </li>
                                    <li class="level2 item123">
                                      <a
                                        class="level2"
                                        ><span><router-link to="./mikrodermabrazja" >Mikrodermabrazja</router-link></span></a
                                      >
                                    </li>
                                    <li class="level2 item127">
                                      <a
                                        class="level2"
                                        ><span><router-link to="./zabiegi-antycelulitowe">Zabiegi antycellulitowe</router-link></span></a
                                      >
                                    </li>
                                  </ul>
                                </li>
                                <li class="level1 item108 parent">
                                  <span class="separator level1 parent"
                                    ><span>Stylizacja paznokci</span></span
                                  >
                                  <ul class="level2">
                                    <li class="level2 item135">
                                      <a
                                        class="level2"
                                        ><span>Żel</span></a
                                      >
                                    </li>
                                    <li class="level2 item136">
                                      <a
                                        class="level2"
                                        ><span>Akryl</span></a
                                      >
                                    </li>
                                    <li class="level2 item137">
                                      <a
                                        class="level2"
                                        ><span>Manicure</span></a
                                      >
                                    </li>
                                    <li class="level2 item138">
                                      <a
                                        class="level2"
                                        ><span>Pedicure</span></a
                                      >
                                    </li>
                                    <li class="level2 item139">
                                      <a
                                        class="level2"
                                        ><span>Hybryba</span></a
                                      >
                                    </li>
                                  </ul>
                                </li>
                                <li class="level1 item140">
                                  <a class="level1"
                                    ><span>Henna</span></a
                                  >
                                </li>
                                <li class="level1 item141">
                                  <a
                                    class="level1"
                                    ><span>Depilacja</span></a
                                  >
                                </li>
                                <li class="level1 item142">
                                  <a
                                    class="level1"
                                    ><span>Pakiety</span></a
                                  >
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div class="grid-box width100 grid-v">
                            <div
                              class="module mod1-box mod-box-default ostatni deepest"
                            >
                              <div>
                                <img alt="" src="images/mary-kay.png" />
                              </div>
                            </div>
                          </div>
                          <div class="grid-box width100 grid-v">
                            <div
                              class="module mod1-box mod-box-default promocje deepest"
                            >
                              <p style="text-align: center">
                                <a
                                  href="https://www.facebook.com/pages/Salon-Urody-Ilona/131403460340161"
                                  target="blank"
                                 >
                                  <img alt="" src="images/facebook-button.png"
                                /></a>
                                <a
                                  href="https://www.instagram.com/salonurody_ilona/"
                                  target="blank"
                                 >
                                  <img style="float:left" alt="" src="images/instagram-logo.png"
                                /></a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div><!-- ------------------------------------------------------------------->>
              </div>
            </div>
          </div>

          <!-- main end -->
        </div>
      </div>
</template>
<script>
export default {
    
}
</script>
<style lang="css">

.AG_classic .ag_imageThumb {
  border-color: #808080;
}
.AG_classic .ag_imageThumb:hover {
  background-color: #fea804;
}

/* PAGINATION AND ALBUM STYLE DEFINITIONS */
#AG_01 a.AG_album_thumb,
#AG_01 div.AG_album_wrap,
#AG_01 a.AG_pagin_link,
#AG_01 a.AG_pagin_prev,
#AG_01 a.AG_pagin_next {
  border-color: #808080;
}
#AG_01 a.AG_album_thumb:hover,
#AG_01 a.AG_pagin_link:hover,
#AG_01 a.AG_pagin_prev:hover,
#AG_01 a.AG_pagin_next:hover {
  background-color: #fea804;
}
#AG_01 div.AG_album_wrap h1,
#AG_01 a.AG_pagin_link,
#AG_01 a.AG_pagin_prev,
#AG_01 a.AG_pagin_next {
  color: #808080;
}

</style>